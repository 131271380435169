import React, {useState, Suspense} from "react";
import {useSelector} from "react-redux";
import {Route, Routes, Navigate} from "react-router-dom";
import useEffectLocation from "../hooks/useEffectLocation";

import './style.scss';

const Scrollbar = React.lazy(() => import("smooth-scrollbar"));

const Landing = React.lazy(() => import("../views/home/Landing"));

const About = React.lazy(() => import("../views/About"));
const Scouts = React.lazy(() => import("../views/Scouts"));
const Contact = React.lazy(() => import("../views/Contact"));
const Careers = React.lazy(() => import("../views/Careers"));

const Router = () => {

    const [transPage, setTransPage] = useState("in");
    const scrollbar: React.MutableRefObject<null | Scrollbar> = useSelector(state => state.scrollbar);

    const location = useEffectLocation((l) => {
        setTransPage("out");
    })

    const onAnimateEnd = () => {
        if (transPage !== "out") return;
        scrollbar.current?.scrollTo(0, 0, 0);
        window.scrollTo({left: 0, top: 0});
        setTransPage("in");
    }


    return (

        <div id="dsn-content" className={`dsn-transition-page dsn-animate-${transPage}`}
             onAnimationEnd={onAnimateEnd}
        >
            <Suspense fallback={<div className="background-main h-100-v" />}>
                <Routes location={location}>

                    <Route path="/" element={<Landing/>}/>
                    <Route exact path="/about" element={<About/>}/>
                    <Route exact path="/scouts" element={<Scouts/>}/>
                    <Route exact path="/careers" element={<Careers/>}/>
                    <Route exact path="/contact" element={<Contact/>}/>
                    <Route exact path="*" element={<Navigate to="/" replace />}/>
                </Routes>
            </Suspense>
        </div>


    );
}

export default React.memo(Router);